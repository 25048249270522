<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.nama_customer)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.nama_customer }}
      </h4>
        <i style="font-weight: bolder;">{{ changed ? 'Unsaved' : null }}</i>
    </b-media>

    <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.nama_customer"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
          <b-col
              cols="12"
              md="4"
          >
              <b-form-group
                  label="No Hp"
                  label-for="no_hp"
              >
                  <b-form-input
                      id="no_hp"
                      v-model="userData.no_hp"
                  />
              </b-form-group>
          </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Gender"
            label-for="gender-list"
          >
            <v-select
              v-model="userData.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="[
                  'Pria','Perempuan'
              ]"
              :clearable="false"
              input-id="gender-list"
            />
          </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="4"
        >
            <b-form-group
                label="Pasword (kosongkan jika tidak diubah)"
                label-for="password"
            >
                <b-form-input
                    type="password"
                    id="password"
                    v-model="userData.password"
                    trim
                />
            <password v-model="userData.password" id="password-meter" strength-meter-only/>
            </b-form-group>
        </b-col>

        <b-col
            cols="12"
            md="4"
        >
            <b-form-group
                label-for="example-datepicker"
                label="Birth date"
            >
                <date-picker
                    v-model="userData.tgl_lahir"
                    value-type="YYYY-MM-DD"
                    name="date"
                    placeholder="Birth date"
                />
            </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Auth OTP"
            label-for="authotp"
          >
            <b-form-input
                readonly="readonly"
              id="authotp"
              v-model="userData.authotp"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="pt-2"
        >
            <b-form-checkbox v-model="userData.status" v-bind:value="1" id="status">Aktif?</b-form-checkbox>
            <b-form-checkbox v-model="userData.offline" v-bind:value="1" id="offline">Offline</b-form-checkbox>
        </b-col>

      </b-row>
  </div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import useUsersList from '../customers-list/useUsersList'
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
      DatePicker  : () => import('vue2-datepicker'),
      Password : () => import('vue-password-strength-meter'),
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
      saved: {
          type: Boolean,
      },
  },
    methods: {
        submit() {
            this.$refs.form.submit()
        }
    },
    watch: {
        'userData': {
            handler: function() {
                this.changed = true
            },
            deep: true
        }
    },
  setup(props) {
    const changed = ref(false);
    const { resolveUserRoleVariant } = useUsersList()
    const userData = props.userData;
    const statusOptions = [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' },
    ]
  const resetuserData = () => {
      window.location.reload()
  }
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })
      const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData)
      const foo = () => {
        changed.value = false
      }
    return {
        foo,
        changed,
      resolveUserRoleVariant,
      avatarText,
        resetuserData,
      statusOptions,
        resetForm,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
