<template>
  <component :is="userData.data === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData.data === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

      <b-overlay
          spinner-variant="primary"
          id="overlay-background"
          variant="transparent"
          opacity="1.00"
          blur="0.5em"
          rounded="sm"
          :show="isSubmit">
          <b-form
            @submit.prevent="onSubmit"
          >
                <user-edit-tab-account
                  :user-data="userData.data"
                  class="mt-2 pt-75"
                  :saved="saved"
                  ref="UserEditFormChild"
                />
              <!-- Action Buttons -->
              <b-row class="p-1">
                  <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                  >
                      Save Changes
                  </b-button>
                  <b-button
                      variant="outline-secondary"
                      type="reset"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                      Reset
                  </b-button>
              </b-row>
          </b-form>
      </b-overlay>
  </component>
</template>

<script>
import {
    BTab, BTabs, BCard, BAlert, BLink, BButton, BForm, BRow, BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
      BForm,
      BRow,
      BOverlay,
    UserEditTabAccount,
  },
  setup() {
    const userData = ref(null)
    const isSubmit = ref(true)
    const USER_APP_STORE_MODULE_NAME = 'app-customer'
    const UserEditFormChild = ref();
    let saved = false;
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
      const onSubmit = () => {
          isSubmit.value = true
          console.log(userData.value.data)
          store.dispatch('app-customer/updateUser',{ id: router.currentRoute.params.id, UserData : userData}).then(response =>  {
              saved = true;
          }).finally(() => {
              isSubmit.value = false
              userData.value.data.password = null
              UserEditFormChild.value.foo()
          })
      }
    store.dispatch('app-customer/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
          userData.value = response.data
          isSubmit.value = false
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    return {
        UserEditFormChild,
        saved,
        isSubmit,
      userData,
        onSubmit,
    }
  },
}
</script>

<style>

</style>
